import { defineMessages } from 'react-intl.macro';

const messages = defineMessages({
    joinTrainingButton: {
        id: 'classBanner.joinButton',
        description: 'The Button to open the training link',
        defaultMessage: 'Join training',
    },
    learningActivityNotFoundError: {
        id: 'classPage.joinTrainingFlashBar.learningActivityNotFoundError',
        description:
            'Error message for handling LearningActivityNotFoundError of the GetMeeting request',
        defaultMessage:
            'The training link is not available (activity not found).',
    },
    meetingNotFoundError: {
        id: 'classPage.joinTrainingFlashBar.meetingNotFoundError',
        description:
            'Error message for handling MeetingNotFoundError of the GetMeeting request',
        defaultMessage:
            'The training link is not available (meeting not found).',
    },
    otherError: {
        id: 'classPage.joinTrainingFlashBar.otherErrors',
        description:
            'Error message for handling other errors of the GetMeeting request',
        defaultMessage: 'The training link is not available.',
    },
    breadcrumbsLabel: {
        id: 'classPage.classBanner.breadcrumbsLabel',
        defaultMessage: 'Breadcrumbs',
    },
});

export default messages;
