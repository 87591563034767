export const joinTrainingErrors = {
    learningActivityNotFoundError: 'LearningActivityNotFound',
    meetingNotFoundError: 'MeetingNotFound',
};

export const buildClassFlashBar = (type, setFlashBarItem, formattedMessage) => {
    return [
        {
            type: type,
            content: formattedMessage,
            dismissible: true,
            dismissLabel: 'Dismiss message',
            ariaRole: type === 'error' ? 'alert' : 'status',
            onDismiss: () => setFlashBarItem([]),
            id: 'message_1',
        },
    ];
};
