import { defineMessages } from 'react-intl.macro';

const messages = defineMessages({
    legacyLabButton: {
        id: 'legacyLab.button',
        defaultMessage: 'Lab issues?',
    },
    legacyLabModalTitle: {
        id: 'legacyLab.modal.title',
        defaultMessage: 'Use legacy lab experience',
    },
    legacyLabModalContent: {
        id: 'legacyLab.modal.content',
        defaultMessage:
            'If you are experiencing problems accessing or using the labs, you can try reverting back to the legacy lab experience by confirming this message and clicking the lab again.',
    },
    legacyLabModalConfirm: {
        id: 'legacyLab.modal.confirm',
        defaultMessage: 'Confirm',
    },
    legacyLabModalClose: {
        id: 'legacyLab.modal.close',
        defaultMessage: 'Close',
    },
});

export default messages;
