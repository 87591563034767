import de from './de-DE';
import en from './en-US';
import es from './es-ES';
import ja from './ja-JP';
import ko from './ko-KR';
import it from './it-IT';
import fr from './fr-FR';
import id from './id-ID';
import pt from './pt-BR';
import th from './th-TH';
import zhCN from './zh-Hans-CN';
import zhTW from './zh-Hant-TW';

const locales = {
    'de-DE': de,
    'en-US': en,
    'es-ES': es,
    'es-LA': es,
    'ja-JP': ja,
    'it-IT': it,
    'fr-FR': fr,
    'id-ID': id,
    'pt-BR': pt,
    'th-TH': th,
    'ko-KR': ko,
    'zh-Hans-CN': zhCN,
    'zh-Hant-TW': zhTW,
};

export default locales;
