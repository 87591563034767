import logger from '../../utils/logger';

const logStyle = color => `font-weight: bold; color: ${color};`;

const loggerMiddleware = () => next => action => {
    const updates = Object.assign({}, action);
    delete updates.type;
    logger.log({
        group: `Action: ${action.type}`,
        logs: [
            // TODO(grayjna): why doesn't store.getState() return current values?
            // [`%c prev state`, logStyle('#9E9E9E;'), getState()],
            ['%c updates', logStyle('#03A9F4'), updates],
            // [`%c next state`, logStyle('#4CAF50;'), nextQueries[name]],
        ],
    });
    next(action);
};

export default loggerMiddleware;
