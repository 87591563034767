import { defineMessages } from 'react-intl.macro';

const messages = defineMessages({
    sectionTitle: {
        id: 'classLabListHeader.title.heading',
        description: 'Section title for the class lab list',
        defaultMessage: 'Labs',
    },
    labsInfo: {
        id: 'classLabListHeader.labs.info',
        description: 'Help text informing when labs will be enabled',
        defaultMessage: 'Labs will be made available by instructors',
    },
});

export default messages;
