import { merge } from 'lodash';
import { statelessPerformFetch } from '../../sagas';
import { useUser } from '../user';
import { useEffect, useState } from 'react';
import { useFlags } from './useFlags';

export class BffClient {
    constructor(tokenProvider, featureFlags) {
        this.tokenProvider = tokenProvider;
        this.featureFlags = featureFlags;
    }

    injectCredentials(requestOpts) {
        const opts = merge(
            {},
            {
                headers: {
                    Authorization: this.tokenProvider(),
                    'Content-Type': 'application/json',
                },
            },
            requestOpts
        );
        return opts;
    }

    getClassroom(classroomId) {
        const encodedClassroomId = encodeURIComponent(classroomId);
        const url = `/GetClassroomV2/${encodedClassroomId}`;
        return statelessPerformFetch(url, this.injectCredentials({}));
    }

    enrollStudent(classroomId) {
        const encodedClassroomId = encodeURIComponent(classroomId);
        return statelessPerformFetch(
            '/EnrollStudent',
            this.injectCredentials({
                method: 'POST',
                body: JSON.stringify({ classroomId: encodedClassroomId }),
            })
        );
    }

    cancelStudentTraining(labId) {
        return statelessPerformFetch(
            `/CancelStudentTraining`,
            this.injectCredentials({
                method: 'POST',
                body: JSON.stringify({ labId }),
            })
        );
    }

    listActiveTrainings(classroomId) {
        return statelessPerformFetch(
            `/ListActiveTrainings/${encodeURIComponent(classroomId)}`,
            this.injectCredentials({})
        );
    }

    listUserClassrooms(opts = {}) {
        const DEFAULT_PAGINATION = {
            paginationOptions: {
                fromItemIndex: 0,
                pageSize: 25,
            },
        };
        const body = JSON.stringify(merge({}, opts, DEFAULT_PAGINATION));
        return statelessPerformFetch(
            `/ListUserClassrooms`,
            this.injectCredentials({
                method: 'POST',
                body,
            })
        );
    }

    getMeeting(classroomId) {
        return statelessPerformFetch(
            `/GetMeeting/${encodeURIComponent(classroomId)}`,
            this.injectCredentials({})
        );
    }

    get hasLoadedToken() {
        return !!this.tokenProvider();
    }
}

export function useApiClient() {
    const { idToken } = useUser();
    const featureFlags = useFlags();
    const [token, tokenSet] = useState();
    useEffect(() => {
        if (idToken) {
            tokenSet(idToken);
        }
    }, [idToken, tokenSet]);
    return new BffClient(() => token, featureFlags);
}
