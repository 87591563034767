import { defineMessages } from 'react-intl.macro';

const messages = defineMessages({
    accessCodeAuthErrorTitle: {
        id: 'accessCode.authError.title',
        description:
            'Title text for when the user does not have access to use an access code',
        defaultMessage: 'Oops! There was an error processing your registration',
    },
    accessCodeAuthErrorContent: {
        id: 'accessCode.authError.content',
        description: 'Title text for when an access code is not usable',
        defaultMessage: 'Please contact your instructor for assistance',
    },
    accessCodeServiceErrorTitle: {
        id: 'accessCode.serviceError.title',
        description: 'Title text for when an access code is not usable',
        defaultMessage: 'Oops! There was an error processing your registration',
    },
    accessCodeServiceErrorContent: {
        id: 'accessCode.serviceError.content',
        description: 'Main copy for when an access code is not usable',
        defaultMessage: 'Please try refreshing the page',
    },
    closeModal: {
        id: 'accessCode.closeModal',
        description: 'Accessible text for the X button in the modal',
        defaultMessage: 'Close',
    },
    closeModalButtonLabel: {
        id: 'accessCodeModal.closeModalButtonLabel',
        defaultMessage: 'Close dialog',
    },
});

export default messages;
