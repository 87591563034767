import { defineMessages } from 'react-intl.macro';

const messages = defineMessages({
    appErrorHeader: {
        id: 'modalAppError.header',
        description: 'Title text for when an error occurred',
        defaultMessage: 'Something went wrong',
    },
    appErrorBody: {
        id: 'modalAppError.body',
        description: 'Main copy for when an error as occurred',
        defaultMessage:
            'Verify the URL is correct and try again in a few minutes',
    },
    authorizationErrorHeader: {
        id: 'modalAuthorizationError.header',
        description: 'Header text for when an auth error occurred',
        defaultMessage: "You don't have access to this class",
    },
    authorizationErrorBody: {
        id: 'modalAuthorizationError.body',
        description: 'Main copy for when an auth error as occurred',
        defaultMessage: 'Please contact your instructor for assistance',
    },
    closeModal: {
        id: 'classErrorModal.close',
        description: 'Accessible text for the X button in the modal',
        defaultMessage: 'Close',
    },
    closeModalButtonLabel: {
        id: 'errorModal.closeModalButtonLabel',
        defaultMessage: 'Close dialog',
    },
});

export default messages;
