import React from 'react';
import { useUser } from '../utils/user';
import classMainMessages from '../ClassMain/ClassMain.messages';
import { useIntl } from 'react-intl';
import './UserKeyDisplay.scss';

const UserKeyDisplay = () => {
    const { userKey } = useUser();
    const { formatMessage } = useIntl();
    if (!userKey) return null;
    return (
        <p className="user-key-display">
            {formatMessage(classMainMessages.userKey)} {userKey}
        </p>
    );
};

export default UserKeyDisplay;
