import { defineMessages } from 'react-intl.macro';

const messages = defineMessages({
    viewAllToggleLink: {
        id: 'baseCard.viewAllToggleLink',
        defaultMessage: 'view all',
    },
    viewLessToggleLink: {
        id: 'baseCard.viewLessToggleLink',
        defaultMessage: 'view less',
    },
});

export default messages;
