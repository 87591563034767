import React from 'react';
import { Spinner } from '@amzn/awsui-components-react';

import './Loader.css';

const Loader = ({ isLoading, hasError, fallback, children, message }) => {
    if (hasError) {
        return fallback ? fallback() : null;
    }

    return isLoading ? (
        <div className="loader" data-testid="loader">
            {message ? <p>{message}</p> : null}
            <Spinner size="large" />
        </div>
    ) : (
        children
    );
};

export default Loader;
