import { defineMessages } from 'react-intl.macro';

const messages = defineMessages({
    guideButtonAriaLabel: {
        id: 'guideButton.button.ariaLabel',
        defaultMessage: '{buttonLabel} (opens a new tab)',
    },
    closeModalButtonLabel: {
        id: 'guideButton.studentGuideModal.closeModalButtonLabel',
        defaultMessage: 'Close dialog',
    },
    studentGuideErrorTitle: {
        id: 'guideButton.studentGuideModal.errorTitle',
        description:
            'Title for the student guide modal when there was an error',
        defaultMessage: 'An error has occurred',
    },
    studentGuideErrorContent: {
        id: 'guideButton.studentGuideModal.errorContent',
        description:
            'Content for the student guide modal when there was an error',
        defaultMessage: 'Please try again.',
    },
    studentGuideErrorModalActionButton: {
        id: 'guideButton.studentGuideModal.errorActionButton',
        description: 'Text for the error modal button',
        defaultMessage: 'Close',
    },
    studentGuideButtonText: {
        id: 'guideButton.studentGuideButton.text',
        description: 'Text for the student guide button',
        defaultMessage: 'Student Guide',
    },
    labGuideButtonText: {
        id: 'guideButton.labGuideButton.text',
        description: 'Text for the lab guide button',
        defaultMessage: 'Lab Guide',
    },
});

export default messages;
