import { defineMessages } from 'react-intl.macro';

const messages = defineMessages({
    awsTAndC: {
        id: 'header.awsTAndC',
        description: 'Name of aws organization',
        defaultMessage: 'AWS Training and Certification',
    },
    logoutBtnLabel: {
        id: 'header.logout.btnLabel',
        defaultMessage: 'Log Out',
    },
    profilePageLabel: {
        id: 'header.profilePageLabel',
        defaultMessage: 'My profile',
    },
    myAcoountLabel: {
        id: 'header.myAcoountLabel',
        defaultMessage: 'My account',
    },
    learningLibraryLink: {
        id: 'header.link.learningLibrary',
        defaultMessage: 'Learning Library',
    },
    skillBuilderLink: {
        id: 'header.link.skillBuilder',
        defaultMessage: 'AWS Skill Builder',
    },
    certificationLink: {
        id: 'header.link.certification',
        defaultMessage: 'Certification',
    },
});

export default messages;
