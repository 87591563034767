import React, { Fragment, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { Button, Modal, SpaceBetween, Box } from '@amzn/awsui-components-react';

import messages from './index.messages';
import { setWindowSearch } from '../utils/urls';

const legacySearch = 'legacy=1';

const LegacyLabButton = () => {
    const [modalOpen, modalOpenSet] = useState(false);
    const { formatMessage } = useIntl();
    const location = useLocation();

    const onCloseClick = () => modalOpenSet(false);

    const onConfirmClick = () => {
        const search =
            location.search.length === 0
                ? legacySearch
                : `${location.search}&${legacySearch}`;
        setWindowSearch(search);
    };

    const isLegacyEnabled = location.search.includes(legacySearch);

    return isLegacyEnabled ? null : (
        <Fragment>
            <Button onClick={() => modalOpenSet(true)} formAction="none">
                {formatMessage(messages.legacyLabButton)}
            </Button>
            <Modal
                visible={modalOpen}
                header={formatMessage(messages.legacyLabModalTitle)}
                closeAriaLabel={formatMessage(messages.legacyLabModalClose)}
                onDismiss={onCloseClick}
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button
                                onClick={onCloseClick}
                                formAction="none"
                                variant="link"
                            >
                                {formatMessage(messages.legacyLabModalClose)}
                            </Button>
                            <Button onClick={onConfirmClick} variant="primary">
                                {formatMessage(messages.legacyLabModalConfirm)}
                            </Button>
                        </SpaceBetween>
                    </Box>
                }
            >
                {formatMessage(messages.legacyLabModalContent)}
            </Modal>
        </Fragment>
    );
};

export default LegacyLabButton;
