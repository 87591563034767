import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { Modal } from '@amzn/awsui-components-react';

import defaultMessages from './ClassErrorModal.messages';

const DEFAULT_ERROR = 'AppError';

const getErrorMessageTypes = messages => ({
    AuthError: {
        header: messages.authorizationErrorHeader,
        body: messages.authorizationErrorBody,
    },
    AppError: {
        header: messages.appErrorHeader,
        body: messages.appErrorBody,
    },
});

export const ClassErrorModal = ({
    intl: { formatMessage },
    error,
    customMessages = defaultMessages,
}) => {
    const [modalVisible, modalVisibleSet] = useState(false);
    const errorMessageTypes = getErrorMessageTypes(customMessages);
    const errorMessage =
        error && errorMessageTypes[error.type]
            ? errorMessageTypes[error.type]
            : errorMessageTypes[DEFAULT_ERROR];
    const header = formatMessage(errorMessage.header);
    const message = formatMessage(errorMessage.body);

    useEffect(() => {
        if (error) modalVisibleSet(true);
    }, [error]);

    return (
        <Modal
            visible={modalVisible}
            data-test="app-modal"
            header={header}
            onDismiss={() => modalVisibleSet(false)}
            closeAriaLabel={formatMessage(
                defaultMessages.closeModalButtonLabel
            )}
        >
            {message}
        </Modal>
    );
};

export default injectIntl(ClassErrorModal);
