import React, { useRef, useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import _get from 'lodash/get';
import { useUserManager } from '../.config/configureAuth';
import { parseURIParams, recordMetrics } from '../utils';
import environment from '../.config/environment';
import { Spinner } from '@amzn/awsui-components-react';

export const ELTIHandler = ({ globals = window }) => {
    const refs = useRef({
        userManager: useUserManager(),
        globals,
    });
    const [signedIn, signedInSet] = useState(false);
    const [classroomId, classroomIdSet] = useState('');
    useEffect(() => {
        const doRedirect = async () => {
            const { userManager, globals } = refs.current;
            const data = await userManager.getUser();
            const targetClassroomURL = _get(
                data,
                'profile.custom:LMS:TargetLinkUri'
            );
            if (targetClassroomURL) {
                const { pathname } = new URL(targetClassroomURL);
                recordMetrics('elti', 'AppMetrics', {
                    metrics: {
                        eltiRedirect: 1,
                        classroomURL: targetClassroomURL,
                    },
                });
                classroomIdSet(pathname);
            } else {
                const { origin, search } = globals?.location;
                const redirect_uri = `${origin}/elti`;
                const { state } = parseURIParams(search.substring(1));
                const {
                    authorizer: { issuer, clientId },
                } = environment(globals);
                const stateObject = {
                    id: state,
                    created: Math.floor(Date.now() / 1000),
                    request_type: 'si:r',
                    code_verifier: 'NOTHING',
                    redirect_uri,
                    authority: issuer,
                    client_id: clientId,
                    response_mode: 'query',
                    scope: 'openid',
                    extraTokenParams: {},
                };
                globals.localStorage.setItem(
                    `oidc.${state}`,
                    JSON.stringify(stateObject)
                );
                await userManager.signinCallback();
                signedInSet(true);
            }
        };
        doRedirect();
    }, [signedIn]);
    return classroomId ? (
        <Redirect to={classroomId} />
    ) : (
        <Spinner data-testid="elti-spinner" />
    );
};
