import React from 'react';
import { useHistory } from 'react-router-dom';
import { Badge, Box, Button } from '@amzn/awsui-components-react';
import moment from 'moment-timezone';
import BaseCard from '../BaseCard';
import messages from './ClassCard.messages';
import { useIntl } from 'react-intl';
import './ClassCard.scss';

const {
    physicalLocationType,
    virtualLocationType,
    openLabBtnLabel,
    activeStatusBadge,
    completedStatusBadge,
    tooEarlyWarning,
} = messages;

const ClassCardSubHeader = ({ locationType, duration, startsOn, endsOn }) => {
    const { formatMessage } = useIntl();
    const isActive = moment().isBetween(
        moment.unix(startsOn),
        moment.unix(endsOn)
    );
    const isUpcoming = moment().isBefore(moment.unix(startsOn));
    const status = isActive ? 'active' : isUpcoming ? 'upcoming' : 'past';
    return (
        <Box>
            <h4 className={`class-card-subheader ${status}`}>
                {isActive ? (
                    <Badge color="green">
                        {formatMessage(activeStatusBadge)}
                    </Badge>
                ) : null}
                {status === 'past' ? (
                    <Badge>{formatMessage(completedStatusBadge)}</Badge>
                ) : null}
                <span className="status">
                    {locationType === 'physical'
                        ? formatMessage(physicalLocationType)
                        : formatMessage(virtualLocationType)}
                </span>
            </h4>

            {duration ? <p>{duration}</p> : null}
        </Box>
    );
};

const ClassCard = ({ classroom = {} }) => {
    const {
        startsOn = 0,
        endsOn = 0,
        classroomArn = '',
        courseDescription = '',
        courseTitle = '',
        locationData = {},
    } = classroom;
    const { formatMessage } = useIntl();

    const timezone = locationData?.timezone || moment.tz.guess(true);
    const unixToMoment = unix => moment.unix(unix).tz(timezone);
    const unixToFull = unix => unixToMoment(unix).format('LLL');
    const timestampAreAvailable = startsOn && endsOn;
    const duration =
        timestampAreAvailable &&
        `${unixToFull(startsOn)} - ${unixToFull(endsOn)}`;
    const [, classroomId] = classroomArn.split('/');
    const history = useHistory();
    const classroomPath = `/class/${encodeURIComponent(classroomId)}`;
    const onClickLinkOverride = e => {
        e.preventDefault();
        history.push(classroomPath);
    };
    const canEnterTheClassroom = unixToMoment(startsOn)
        .add(-1, 'hour')
        .isSameOrBefore(moment());
    return (
        <BaseCard
            title={courseTitle}
            description={courseDescription}
            subHeader={
                <ClassCardSubHeader
                    startsOn={startsOn}
                    endsOn={endsOn}
                    locationType={locationData?.locationType}
                    duration={duration}
                />
            }
            align="right"
        >
            {!canEnterTheClassroom && (
                <span className="class-card__too-early-warning">
                    {formatMessage(tooEarlyWarning)}
                </span>
            )}
            <Button
                disabled={!canEnterTheClassroom}
                href={classroomPath}
                onClick={onClickLinkOverride}
            >
                {formatMessage(openLabBtnLabel)}
            </Button>
        </BaseCard>
    );
};

export default ClassCard;
