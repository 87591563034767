import React from 'react';
import { useIntl } from 'react-intl';
import 'moment/min/locales';

import LabCard from '../LabCard';
import './LabList.scss';
import { translateDurationUnit } from '../utils/helpers';
import { useActiveTrainings } from '../ActiveTrainingsProvider';

const LabList = ({
    data,
    onClassClick,
    activeTrainings = new Set([]),
    titleRegex,
}) => {
    const { locale } = useIntl();
    const { trainings: loadedLabs } = useActiveTrainings();

    if (!data || !data.content) return null;

    const labList = data.course.content
        .filter(v => data.content[v])
        .map((id, i) => ({
            ...data.content[id],
            id,
            disabled: !activeTrainings.has(id),
            onClick: onClassClick.bind(null, id),
        }))
        .map(({ duration, durationUnit, title, ...rest }) => ({
            ...rest,
            title: title.replace(titleRegex, ''),
            duration: translateDurationUnit(duration, durationUnit, locale),
        }));

    return (
        <ol className="lab-list awsui-util-pl-n">
            {labList.map((lab, index) => {
                const { labId = '', labStatus = '', bunsenLabStatus = '' } =
                    loadedLabs?.find(l => lab.id === l.arn) || {};
                return (
                    <li key={lab.id}>
                        <LabCard
                            {...lab}
                            labStatus={labStatus}
                            labId={labId}
                            index={index}
                            bunsenLabStatus={bunsenLabStatus}
                        />
                    </li>
                );
            })}
        </ol>
    );
};

export default LabList;
