import React from 'react';
import { Box, Button, Icon } from '@amzn/awsui-components-react';
import { useIntl } from 'react-intl';

import messages from './JamLabCard.messages';
import BaseCard from '../BaseCard/';
import { translateDurationUnit } from '../utils/helpers';
import moment from 'moment';

const {
    jamUnavailableBtnLabel,
    jamUnavailableBtnText,
    jamStatusPending,
    jamStatusUpdating,
    jamStatusNotStarted,
    jamStatusOngoing,
    jamStatusEnded,
    jamStatusCancelled,
    jamStatusError,
    jamCardTitle,
    jamCardDescription,
    enabledLabBtnText,
} = messages;

const JAM_EVENT_PENDING = 'PENDING';
const JAM_EVENT_PENDING_APPROVAL = 'PENDING_APPROVAL';
const JAM_EVENT_UPDATING = 'UPDATING';
const JAM_EVENT_NOT_STARTED = 'NOT_STARTED';
const JAM_EVENT_ONGOING = 'ONGOING';
const JAM_EVENT_ENDED = 'ENDED';
const JAM_EVENT_CANCELLED = 'CANCELLED';
const JAM_EVENT_ERROR = 'ERROR';

const getStatusBadgeColor = status => {
    switch (status) {
        case JAM_EVENT_ONGOING:
        case JAM_EVENT_NOT_STARTED:
            return {
                color: 'text-status-success',
                icon: 'status-positive',
            };
        case JAM_EVENT_ENDED:
        case JAM_EVENT_CANCELLED:
            return {
                color: 'text-status-inactive',
                icon: 'status-stopped',
            };
        case JAM_EVENT_ERROR:
            return {
                color: 'text-status-error',
                icon: 'status-negative',
            };
        default:
            return {
                color: 'text-status-inactive',
                icon: null,
            };
    }
};

const getStatusTranslationMessageBase = formatFn => status => {
    switch (status) {
        case JAM_EVENT_PENDING:
        case JAM_EVENT_PENDING_APPROVAL:
            return formatFn(jamStatusPending);
        case JAM_EVENT_UPDATING:
            return formatFn(jamStatusUpdating);
        case JAM_EVENT_NOT_STARTED:
            return formatFn(jamStatusNotStarted);
        case JAM_EVENT_ONGOING:
            return formatFn(jamStatusOngoing);
        case JAM_EVENT_ENDED:
            return formatFn(jamStatusEnded);
        case JAM_EVENT_CANCELLED:
            return formatFn(jamStatusCancelled);
        case JAM_EVENT_ERROR:
            return formatFn(jamStatusError);
        default:
            return '-';
    }
};

const JamActions = ({ eventURL, eventStatus, disabled }) => {
    const { formatMessage } = useIntl();
    const isActiveStatus = [JAM_EVENT_NOT_STARTED, JAM_EVENT_ONGOING].includes(
        eventStatus
    );
    const buttonLabel = disabled ? jamUnavailableBtnText : enabledLabBtnText;
    return (
        <Box textAlign="right" data-testid="lab-card__jam-actions">
            {(disabled || isActiveStatus) && (
                <Button
                    disabled={disabled}
                    ariaLabel={
                        disabled ? formatMessage(jamUnavailableBtnLabel) : null
                    }
                    onClick={() => window.open(eventURL)}
                >
                    {formatMessage(buttonLabel)}
                </Button>
            )}
        </Box>
    );
};

const JamLabCard = ({
    disabled,
    startsOn = 0,
    endsOn = 0,
    courseTitle,
    eventURL,
    eventStatus = '',
}) => {
    const { formatMessage, locale } = useIntl();
    const containerClasses = ['lab-card__actions'];
    if (!disabled) {
        containerClasses.push('jam-event');
    }
    const title = `${formatMessage(jamCardTitle)} - ${courseTitle}`;
    const description = formatMessage(jamCardDescription);
    const eventDurationInSeconds = endsOn - startsOn;
    const duration = translateDurationUnit(
        moment.duration(eventDurationInSeconds, 'seconds'),
        'hour',
        locale
    );
    const badgeMessage = getStatusTranslationMessageBase(formatMessage);
    return (
        <BaseCard
            title={title}
            duration={duration}
            description={description}
            align={disabled ? 'right' : 'left'}
            className="jam-lab-card"
            badge={
                !disabled && (
                    <Box
                        color={getStatusBadgeColor(eventStatus).color}
                        data-testid="lab-card__jam-actions-badge"
                    >
                        <Icon variant={getStatusBadgeColor(eventStatus).icon} />
                        {badgeMessage(eventStatus)}
                    </Box>
                )
            }
        >
            <JamActions
                eventURL={eventURL}
                eventStatus={eventStatus}
                disabled={disabled}
            />
        </BaseCard>
    );
};

export default JamLabCard;
