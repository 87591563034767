import { defineMessages } from 'react-intl.macro';

const messages = defineMessages({
    appErrorHeader: {
        id: 'withUserAttributes.appErrorHeader',
        defaultMessage: 'Your profile could not be retrieved.',
    },
    appErrorBody: {
        id: 'withUserAttributes.appErrorBody',
        defaultMessage: 'Please try again in a few moments.',
    },
    authorizationErrorHeader: {
        id: 'withUserAttributes.authorizationErrorHeader',
        defaultMessage: "You don't have permission to obtain your profile.",
    },
    authorizationErrorBody: {
        id: 'withUserAttributes.authorizationErrorBody',
        defaultMessage: 'Please contact your instructor for assistance.',
    },
});

export default messages;
