import { numBetween, nowTime } from '.';

const poll = (fn, opts = {}, ...args) => {
    const {
        interval,
        timeout,
        onError,
        retries = 5,
        retryDelay = 100,
        onTimeoutIdChange,
    } = opts;
    const endTime = timeout ? nowTime() + timeout : undefined;
    let id;
    let attempt = 1;

    if (!onError)
        throw new Error(
            'poll requires an `onError` handler property defined in options'
        );

    const start = async () => {
        try {
            const response = await fn(...args);
            attempt = 1;
            if (response && timeout) {
                return Promise.resolve(response);
            } else if (timeout && nowTime() > endTime) {
                onError('Poll timeout');
                return;
            } else if (interval) {
                id = setTimeout(
                    start,
                    numBetween(interval * 0.75, interval * 1.25)
                );
                if (onTimeoutIdChange) onTimeoutIdChange(id);
            }
        } catch (e) {
            onError(e);
            if (attempt > retries) {
                onError('Retry limit reached');
            } else {
                attempt++;
                id = setTimeout(start, numBetween(retryDelay, retryDelay * 3));
                if (onTimeoutIdChange) onTimeoutIdChange(id);
            }
        }
    };

    return {
        start,
        stop: () => window.clearTimeout(id),
    };
};

export default poll;
