import React, { Fragment } from 'react';
import { injectIntl } from 'react-intl';
import _get from 'lodash/get';
import { Grid, Box, SpaceBetween } from '@amzn/awsui-components-react';

import { isBeforeTimestamp, adjustUnixTimestamp } from '../utils';
import { SectionTitle } from '../Section';

import messages from './ClassLabListHeader.messages';
import guideMessages from '../GuideButton/GuideButton.messages';
import './ClassLabListHeader.scss';
import GuideButton from '../GuideButton';
import LegacyLabButton from '../LegacyLabButton';

const ClassLabListHeader = ({
    intl: { formatMessage },
    startsOn,
    classId,
    books,
    showLegacyLabButton,
}) => {
    const hasTheClassStarted = isBeforeTimestamp(adjustUnixTimestamp(startsOn));
    const disableLabGuideButton =
        !hasTheClassStarted || !_get(books, 'labGuide.vbId');
    const disableStudentGuideButton =
        !hasTheClassStarted || !_get(books, 'studentGuide.vbId');

    return (
        <Fragment>
            <div className="awsui-grid awsui-util-pb-l">
                <SectionTitle>
                    {formatMessage(messages.sectionTitle)}
                </SectionTitle>

                <Grid
                    disableGutters
                    gridDefinition={[
                        { colspan: { default: 12, xs: 6 } },
                        { colspan: { default: 12, xs: 6 } },
                    ]}
                >
                    <p>{formatMessage(messages.labsInfo)}</p>
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            {showLegacyLabButton ? <LegacyLabButton /> : null}
                            <GuideButton
                                guideType="lab"
                                btnText={guideMessages.labGuideButtonText}
                                disabled={disableLabGuideButton}
                                classId={classId}
                            />
                            <GuideButton
                                guideType="student"
                                btnText={guideMessages.studentGuideButtonText}
                                disabled={disableStudentGuideButton}
                                classId={classId}
                            />
                        </SpaceBetween>
                    </Box>
                </Grid>
            </div>
        </Fragment>
    );
};

export default injectIntl(ClassLabListHeader);
