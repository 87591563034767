import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import logo from './logo.svg';
import { ButtonDropdown } from '@amzn/awsui-components-react';
import messages from './ClassHeader.messages';
import './ClassHeader.scss';
import { useLocation } from 'react-router';
import {
    PATH_KEY,
    SEARCH_KEY,
    LOGOUT_KEY,
    LOGOUT_IDP_KEY,
} from '../utils/storage-keys';
import { cognitoSignoutClient } from '../utils/api';
import {
    KIKU_COURSE_SEARCH_URL,
    SKILLBUILDER_URL,
    CERTIFICATION_URL,
} from '../utils/external-urls';

export const ClassHeader = ({
    intl: { formatMessage },
    userManager,
    globals = window,
}) => {
    const signOut = cognitoSignoutClient(globals);
    const storage = globals.sessionStorage;
    const location = useLocation();
    const path = location.pathname.match(/\/class\/(?<classroomId>\w+)/);
    const classroomId = path?.groups?.classroomId;

    const [data, dataSet] = useState({});

    const logout = async () => {
        if (classroomId) {
            storage.setItem(PATH_KEY, location.pathname);
            storage.setItem(SEARCH_KEY, location.search);
            storage.setItem(LOGOUT_KEY, classroomId);
        } else {
            storage.setItem(PATH_KEY, '/');
            storage.setItem(LOGOUT_KEY, '/');
        }
        const { refresh_token, profile } = await userManager.getUser();
        const idpName = profile?.public_provider_name || 'COGNITO';
        storage.setItem(LOGOUT_IDP_KEY, idpName);
        await signOut(refresh_token);
        userManager.signoutRedirect();
    };

    const onItemClick = async event => {
        if (event.detail.id === 'signOut') {
            await logout();
        }
    };

    useEffect(() => {
        userManager.getUser().then(dataSet);
    }, [userManager, userManager.events]);

    useEffect(() => {
        userManager.events.addUserLoaded(dataSet);
    }, [userManager.events]);

    const showLink = location.pathname === '/';
    return (
        <header className="class-header">
            <div>
                <img src={logo} alt={formatMessage(messages.awsTAndC)} />
                {showLink && (
                    <section role="group" className="class-header__link-group">
                        <a href={KIKU_COURSE_SEARCH_URL}>
                            {formatMessage(messages.learningLibraryLink)}
                        </a>
                        <a href={SKILLBUILDER_URL}>
                            {formatMessage(messages.skillBuilderLink)}
                        </a>
                        <a href={CERTIFICATION_URL}>
                            {formatMessage(messages.certificationLink)}
                        </a>
                    </section>
                )}
            </div>
            <ButtonDropdown
                items={[
                    {
                        text: data?.profile?.email,
                        id: 'email',
                        disabled: true,
                    },
                    {
                        text: formatMessage(messages.profilePageLabel),
                        id: 'profilePage',
                        href: '/profile',
                    },
                    {
                        text: formatMessage(messages.logoutBtnLabel),
                        id: 'signOut',
                    },
                ]}
                onItemClick={onItemClick}
            >
                {formatMessage(messages.myAcoountLabel)}
            </ButtonDropdown>
        </header>
    );
};

export default injectIntl(ClassHeader);
