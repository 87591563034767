import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { Modal } from '@amzn/awsui-components-react';
import Loader from '../Loader';

import { useQuery } from '../utils';
import { useUser } from '../utils/user';
import { performFetch } from '../sagas';
import { generateIdempotencyToken } from '../utils';
import messages from './withUseCode.messages';

const DEFAULT_ERROR = 'ServiceError';

const errorMessageTypes = {
    AuthError: {
        header: messages.accessCodeAuthErrorTitle,
        body: messages.accessCodeAuthErrorContent,
    },
    ServiceError: {
        header: messages.accessCodeServiceErrorTitle,
        body: messages.accessCodeServiceErrorContent,
    },
};

const withUseCode = Component =>
    injectIntl(({ intl: { formatMessage }, accessCode, classroomId }) => {
        const [modalVisible, modalVisibleSet] = useState(false);
        const { idToken } = useUser();
        const { fetchDispatch, state } = useQuery('useAccessCode');
        const { error, data } = state;
        const errorMessage =
            error && errorMessageTypes[error.type]
                ? errorMessageTypes[error.type]
                : errorMessageTypes[DEFAULT_ERROR];
        const header = formatMessage(errorMessage.header);
        const message = formatMessage(errorMessage.body);

        useEffect(() => {
            if (!accessCode || !classroomId || !idToken) return;

            performFetch(fetchDispatch, {
                idToken,
                params: {
                    method: 'POST',
                    body: {
                        classroomId,
                        code: accessCode,
                        idempotencyToken: generateIdempotencyToken(),
                    },
                },
                api: state,
                retryOptions: {
                    retries: 7,
                },
            });
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [accessCode, classroomId, idToken]);

        useEffect(() => {
            if (error) modalVisibleSet(true);
        }, [error]);

        return (
            <Loader
                isLoading={!data && accessCode}
                hasError={error && modalVisible}
                fallback={() => (
                    <Modal
                        visible
                        onDismiss={() => modalVisibleSet(false)}
                        closeAriaLabel={formatMessage(
                            messages.closeModalButtonLabel
                        )}
                        header={header}
                    >
                        {message}
                    </Modal>
                )}
            >
                <Component classroomId={classroomId} />
            </Loader>
        );
    });

export default withUseCode;
