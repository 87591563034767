import React, { useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { useIntl } from 'react-intl';
import { Helmet } from 'react-helmet';
import { Box, Button, SegmentedControl } from '@amzn/awsui-components-react';
import moment from 'moment-timezone';

import { useApiClient } from '../utils/hooks/useApiClient';
import { KIKU_COURSE_SEARCH_URL } from '../utils/external-urls';
import ClassBanner from '../ClassBanner/';
import Loader from '../Loader/';
import ClassCard from '../ClassCard/';
import messages from './StudentLandingPage.messages';
import './StudentLandingPage.scss';
import { breadcrumbMessages } from '../utils/breadcrumbs';
import UserKeyDisplay from '../UserKeyDisplay/UserKeyDisplay';
import { useUserManager } from '../.config/configureAuth';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import { useLangLocale } from '../LangLocaleProvider';

const {
    upcomingAndActiveClassesBtnLabel,
    pastClassesBtnLabel,
    segmentedButtonLabel,
    loaderMessage,
    noContentHeading,
    noUpcomingBody,
    noContentbody,
    searchClassesBtnLabel,
} = messages;

const WAIT_HOURS_FOR_CLASS = 24;
const FILTER_UPCOMING = 'upcoming';
const FILTER_PAST = 'past';

const NoContentPlaceholder = ({ isForUpcoming }) => {
    const { formatMessage } = useIntl();
    return (
        <div className="no-classes-found-container">
            <Box textAlign="center" variant="h4" margin={{ top: 'xl' }}>
                {formatMessage(noContentHeading)}
            </Box>

            {isForUpcoming ? (
                <Box>
                    {formatMessage(noUpcomingBody, {
                        num: WAIT_HOURS_FOR_CLASS,
                    })}
                </Box>
            ) : (
                <Box textAlign="center">{formatMessage(noContentbody)}</Box>
            )}
            <Box textAlign="center" margin={{ top: 'l' }}>
                <Button href={KIKU_COURSE_SEARCH_URL}>
                    {formatMessage(searchClassesBtnLabel)}
                </Button>
            </Box>
        </div>
    );
};

const StudentLandingPage = () => {
    const { formatMessage } = useIntl();
    const langLocale = useLangLocale();
    const resetLocale = useRef(langLocale.resetLocale);
    const userManager = useUserManager();
    const bffClient = useRef();
    bffClient.current = useApiClient();
    const location = useLocation();
    const [filter, filterSet] = useState(FILTER_UPCOMING);

    useEffect(() => {
        resetLocale.current();
    }, []);

    const queryFn = ({ queryKey }) => {
        const [, filter, timestamp] = queryKey;
        const request = {
            filterOptions: [
                {
                    optionType: 'dateRange',
                    attribute: 'classEndDate',
                    value: {
                        [filter === FILTER_UPCOMING
                            ? 'after'
                            : 'before']: timestamp,
                    },
                },
            ],
        };
        return bffClient.current.listUserClassrooms(request);
    };

    const MINUTE_IN_MS = 60 * 1000;

    const { isLoading, data } = useQuery(
        ['ListUserClassrooms', filter, moment().unix()],
        queryFn,
        {
            enabled: bffClient.current.hasLoadedToken,
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            refetchOnReconnect: false,
            staleTime: 15 * MINUTE_IN_MS,
            cacheTime: 20 * MINUTE_IN_MS,
            onError: error => {
                if (error?.status === 401) {
                    userManager.signinRedirect({
                        state: {
                            pathname: location.pathname,
                            search: location.search,
                        },
                    });
                }
            },
        }
    );

    const hasClassrooms = data?.classrooms?.length > 0;

    return (
        <>
            <Helmet>
                <title>
                    {`${formatMessage(
                        breadcrumbMessages.myClasses
                    )} - AWS Training and Certification`}
                </title>
            </Helmet>
            <ClassBanner
                breadcrumbItems={[]}
                title={
                    <>
                        <span>
                            {formatMessage(breadcrumbMessages.myClasses)}
                        </span>
                        <Box float="right">
                            <SegmentedControl
                                selectedId={filter}
                                onChange={({ detail }) =>
                                    filterSet(detail.selectedId)
                                }
                                label={formatMessage(segmentedButtonLabel)}
                                options={[
                                    {
                                        text: formatMessage(
                                            upcomingAndActiveClassesBtnLabel
                                        ),
                                        id: FILTER_UPCOMING,
                                    },
                                    {
                                        text: formatMessage(
                                            pastClassesBtnLabel
                                        ),
                                        id: FILTER_PAST,
                                    },
                                ]}
                            />
                        </Box>
                    </>
                }
                isStudentLandingPage={true}
            />
            <Loader
                isLoading={isLoading}
                message={formatMessage(loaderMessage)}
            >
                {!hasClassrooms && (
                    <NoContentPlaceholder
                        isForUpcoming={filter === FILTER_UPCOMING}
                    />
                )}
                <div className="student-landing-container">
                    {hasClassrooms &&
                        data.classrooms.map((classroom, i) => (
                            <ClassCard
                                classroom={classroom}
                                key={classroom.classroomArn}
                            />
                        ))}
                </div>
            </Loader>
            <UserKeyDisplay />
        </>
    );
};

export default StudentLandingPage;
