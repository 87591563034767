import { defineMessages } from 'react-intl.macro';

const messages = defineMessages({
    headerLabel: {
        id: 'profilePage.headerLabel',
        defaultMessage: 'Complete your profile',
    },
    updateProfileHeaderLabel: {
        id: 'profilePage.updateProfileHeaderLabel',
        defaultMessage: 'Profile',
    },
    description: {
        id: 'profilePage.description',
        defaultMessage:
            'It looks like we are missing some of your profile information. Please complete your profile to continue.',
    },
    updateProfileDescription: {
        id: 'profilePage.updateProfileDescription',
        defaultMessage: 'Manage your profile information here.',
    },
    firstNameLabel: {
        id: 'profilePage.firstNameLabel',
        defaultMessage: 'First name',
    },
    lastNameLabel: {
        id: 'profilePage.lastNameLabel',
        defaultMessage: 'Last name',
    },
    saveButtonLabel: {
        id: 'profilePage.saveButtonLabel',
        defaultMessage: 'Save',
    },
    saveAndContinueButtonLabel: {
        id: 'profilePage.saveAndContinueButtonLabel',
        defaultMessage: 'Save and continue',
    },
    cancelButtonLabel: {
        id: 'profilePage.cancelButtonLabel',
        defaultMessage: 'Cancel',
    },
    invalidFirstName: {
        id: 'profilePage.invalidFirstName',
        defaultMessage: 'First name is invalid',
    },
    invalidLastName: {
        id: 'profilePage.invalidLastName',
        defaultMessage: 'Last name is invalid',
    },
    appErrorHeader: {
        id: 'profilePage.appErrorHeader',
        defaultMessage: 'Your profile could not be updated.',
    },
    appErrorBody: {
        id: 'profilePage.appErrorBody',
        defaultMessage:
            'Verify your information is correct and try again in a few moments.',
    },
    authorizationErrorHeader: {
        id: 'profilePage.authorizationErrorHeader',
        defaultMessage: "You don't have permission to perform this action.",
    },
    authorizationErrorBody: {
        id: 'profilePage.authorizationErrorBody',
        defaultMessage: 'Please contact your instructor for assistance.',
    },
    updateSuccessMessage: {
        id: 'profile.updateSuccessMessage',
        defaultMessage: 'Profile saved.',
    },
});

export default messages;
