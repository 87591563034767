import {
    FETCH_REQUEST,
    FETCH_ERROR,
    FETCH_RETRY,
    FETCH_SUCCESS,
    CLEAR_DATA,
} from '../actions';
import { nowTime } from '../utils';

const fetcherReducer = (state, action) => {
    switch (action.type) {
        case CLEAR_DATA:
            return {
                ...state,
                data: null,
            };
        case FETCH_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case FETCH_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error,
                lastReceived: nowTime(),
            };
        case FETCH_RETRY:
            return {
                ...state,
                loading: true,
                error: action.error,
                lastReceived: nowTime(),
            };
        case FETCH_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                data: action.data,
                lastReceived: nowTime(),
            };
        default:
            return state;
    }
};

export default fetcherReducer;
