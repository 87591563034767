export class CustomRedirectNavigator {
    constructor(globals = window) {
        this.globals = globals;
    }

    prepare() {
        return Promise.resolve(this);
    }

    navigate(params) {
        if (!params || !params.url) {
            return Promise.reject(new Error('No url provided'));
        }

        const url = new URL(params.url);
        if (url.pathname.includes('logout')) {
            url.searchParams.delete('id_token_hint');
        }
        if (params.useReplaceToNavigate) {
            this.globals.location.replace(url.toString());
        } else {
            this.globals.location = url.toString();
        }

        return Promise.resolve();
    }

    get url() {
        return this.globals.location.href;
    }
}
