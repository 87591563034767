// Fetch actionTypes and actionCreators
export const FETCH_REQUEST = 'FETCH_REQUEST';
export const FETCH_ERROR = 'FETCH_ERROR';
export const FETCH_RETRY = 'FETCH_RETRY';
export const FETCH_SUCCESS = 'FETCH_SUCCESS';
export const CLEAR_DATA = 'CLEAR_DATA';

export const clearData = () => ({ type: CLEAR_DATA });
export const fetchRequest = () => ({ type: FETCH_REQUEST });

export const fetchError = error => ({
    error,
    type: FETCH_ERROR,
});

export const fetchRetry = error => ({
    error,
    type: FETCH_RETRY,
});

export const fetchSuccess = data => ({
    data,
    type: FETCH_SUCCESS,
});
