import React, { createContext, useContext, useRef, useState } from 'react';
import { I18nProvider } from '@amzn/awsui-components-react/polaris/i18n';
import polarisMessages from '@amzn/awsui-components-react/polaris/i18n/messages/all.all';
import { IntlProvider } from 'react-intl';
import translations from './../i18n';

export const LangLocaleContext = createContext();

export const useLangLocale = () => {
    const ctx = useContext(LangLocaleContext);
    if (!ctx) {
        throw new Error('Set LangLocaleProvider first');
    }
    return ctx;
};

const LangLocaleProvider = ({ children, value }) => {
    const [locale, localeSet] = useState(value);
    const initialLocale = useRef(value);
    const initialValue = {
        locale,
        localeSet,
        resetLocale: () => localeSet(initialLocale.current),
    };
    const messages = translations[locale];
    return (
        <LangLocaleContext.Provider value={initialValue}>
            <I18nProvider locale={locale} messages={[polarisMessages]}>
                <IntlProvider locale={locale} messages={messages}>
                    {children}
                </IntlProvider>
            </I18nProvider>
        </LangLocaleContext.Provider>
    );
};

export default LangLocaleProvider;
