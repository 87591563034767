import React, { useContext, createContext } from 'react';
import environment from '../../.config/environment';

export const FlagContext = createContext();

export const FlagContextProvider = ({ children, globals = window }) => (
    <FlagContext.Provider value={environment(globals)?.featureFlags}>
        {children}
    </FlagContext.Provider>
);

export const useFlags = () => useContext(FlagContext);
