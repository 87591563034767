import { defineMessages } from 'react-intl.macro';

const messages = defineMessages({
    classPageLongLoad: {
        id: 'ClassPageLongLoad.text',
        description:
            'Message to show when app is consitently running into 403s',
        defaultMessage: 'Adding you to the system...',
    },
    classPageLocationInPersonClass: {
        id: 'ClassPage.location.physical',
        description: 'Icon label for a physical classroom',
        defaultMessage: 'In-Person Class',
    },
    classPageLocationVirtualClassroom: {
        id: 'ClassPage.location.virtual',
        description: 'Icon label for a virtual classroom',
        defaultMessage: 'Virtual Class',
    },
});

export default messages;
