import React from 'react';
import { injectIntl } from 'react-intl';

import messages from './ErrorPage.messages';
import './ErrorPage.scss';

const ErrorPage = ({ intl: { formatMessage } }) => (
    <section>
        <div className="error-page-body">
            <h1 className="awsui-text-large">
                {formatMessage(messages.pageHeading)}
            </h1>
            <h2>{formatMessage(messages.pageNotFound)}</h2>
            <p>{formatMessage(messages.pageAdvice)}</p>
        </div>
    </section>
);

export default injectIntl(ErrorPage);
