import React from 'react';
import PropTypes from 'prop-types';
import './Section.scss';

/**
 * Section component for adding padding and max-width with auto left and right margins
 * @param children - React nodes to render in the section
 * @param ariaRole - An aria role to apply to the section for accessiblity
 * @returns {React.Element}
 */
const Section = ({ children, ariaRole, className }) => (
    <div data-testid="section-wrapper" className={className}>
        <section role={ariaRole}>{children}</section>
    </div>
);

Section.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    ariaRole: PropTypes.string,
    className: PropTypes.string,
};

export const SectionTitle = ({ children, ...rest }) => (
    <h2 className="section-title" {...rest}>
        {children}
    </h2>
);

export default Section;
