import React from 'react';
import { useIntl } from 'react-intl';
import JamLabCard from '../JamLabCard/JamLabCard';
import messages from './JamTrainings.messages';
import Section, { SectionTitle } from '../Section/Section';
import { Box } from '@amzn/awsui-components-react';

const { sectionTitle, subtitle } = messages;

const JamTrainings = ({ trainings, activeTrainings, courseTitle }) => {
    const { formatMessage } = useIntl();
    if (!trainings || !trainings.length) return null;
    return (
        <div className="class-lab-list-container">
            <Section>
                <SectionTitle>{formatMessage(sectionTitle)}</SectionTitle>
                <Box>{formatMessage(subtitle)}</Box>
                <ol className="lab-list awsui-util-pl-n">
                    {trainings.map(jam => {
                        const {
                            contentId,
                            metaData,
                            fulfillmentStatus,
                            trainingId,
                        } = jam;
                        const props = {
                            courseTitle,
                            eventURL: metaData?.eventURL,
                            eventStatus:
                                metaData?.eventStatus || fulfillmentStatus,
                            startsOn: metaData?.startsOn,
                            endsOn: metaData?.endsOn,
                            disabled: !activeTrainings.has(contentId),
                        };
                        return (
                            <li key={trainingId}>
                                <JamLabCard {...props} />
                            </li>
                        );
                    })}
                </ol>
            </Section>
        </div>
    );
};

export default JamTrainings;
