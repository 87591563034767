import { defineMessages } from 'react-intl.macro';

const messages = defineMessages({
    modalTitle: {
        id: 'logout.modal.title',
        description: 'Top of the logout modal',
        defaultMessage: "You're signed out",
    },
    modalBody: {
        id: 'logout.modal.body',
        description: 'Main modal contents',
        defaultMessage:
            'You are no longer signed into AWS Classrooms. As a note, you are still enrolled in the course. If you would like to access the course, please try signing in again',
    },
    modalSigninBtnLabel: {
        id: 'logout.modal.signinBtn',
        description: 'Sign In Button',
        defaultMessage: 'Sign in',
    },
    modalTrainingBtnLabel: {
        id: 'logout.modal.trainingBtn',
        description: 'Label for button to go to aws.training',
        defaultMessage: 'Return to AWS.Training site',
    },
    signoutInstructions: {
        id: 'logout.signoutInstructions',
        defaultMessage: 'To completely sign out, please follow {link}',
    },
    signoutInstructionsLink: {
        id: 'logout.signoutInstructions.link',
        defaultMessage: 'these instructions',
    },
    awsPartnerSignoutInstructions: {
        id: 'logout.signoutInstructions.awsPartner',
        defaultMessage:
            'To completely sign out, please visit {link} and click on logout.',
    },
    awsPartnerSignoutInstructionsLink: {
        id: 'logout.signoutInstructions.awsPartner.link',
        defaultMessage: 'AWS Partner Central',
    },
    essoSignoutInstructions: {
        id: 'logout.signoutInstructions.esso',
        defaultMessage:
            'To completely sign out, logout from your Enterprise SSO.',
    },
    instructionsHeader: {
        id: 'logout.signoutInstructions.instructionsHeader',
        defaultMessage: 'You may be logged in with {provider}',
    },
    pageTitle: {
        id: 'logout.page.title',
        defaultMessage: 'Logged out',
    },
});

export default messages;
