import Analytics from '@aws-amplify/analytics';

import { JS_ERROR_TYPE } from './errors';
import {
    StudentUIAnalyticsProvider,
    ANALYTICS_PROVIDER_NAME,
} from './analytics-provider';
import { apis } from './api';

let hasConfigured = false;

export const AUTH_START_KEY = 'aws.beaker.authStart';

const configureAnalytics = () => {
    if (hasConfigured) return;

    hasConfigured = true;

    Analytics.addPluggable(
        new StudentUIAnalyticsProvider('Analytics', ANALYTICS_PROVIDER_NAME)
    );

    Analytics.configure({
        [ANALYTICS_PROVIDER_NAME]: {
            clientLoggingUrl: apis.sendClientLog.baseUrl,
        },
    });

    Analytics.autoTrack('session', {
        enable: true,
        provider: ANALYTICS_PROVIDER_NAME,
    });
};

export const recordMetrics = (name, type, additionalProperties = {}) => {
    configureAnalytics();

    Analytics.record(
        {
            name,
            type,
            env: {
                url: window.location.href,
                userAgent: window.navigator && window.navigator.userAgent,
            },
            ...additionalProperties,
        },
        ANALYTICS_PROVIDER_NAME
    );
};

export const recordTimingMetrics = () => {
    // Place on stack so that page has fully loaded before trying to access loadEventEnd
    setTimeout(() => {
        const metrics = {};
        const {
            domContentLoadedEventEnd,
            domContentLoadedEventStart,
            domComplete,
            domInteractive,
            domLoading,
            fetchStart,
            loadEventEnd,
            navigationStart,
            redirectStart,
            responseEnd,
            responseStart,
            requestStart,
        } = performance.timing;
        const startTime = redirectStart || fetchStart || requestStart;
        const endTime =
            domContentLoadedEventEnd ||
            domInteractive ||
            domComplete ||
            loadEventEnd;

        metrics.pageLoad = endTime - startTime;
        metrics.interactive = domInteractive - domLoading;
        metrics.contentLoaded = domContentLoadedEventStart - domLoading;
        metrics.complete = domComplete - domLoading;
        metrics.ttfb = responseStart - requestStart;
        metrics.latency = responseEnd - fetchStart;
        metrics.firstPaint = loadEventEnd - navigationStart;

        // Log the metrics
        recordMetrics('_performance.timing', 'AppMetrics', { metrics });
    });
};

export const recordError = ({ name, message, type = JS_ERROR_TYPE }) =>
    recordMetrics(name, type, { attributes: { message } });
