import { useReducer } from 'react';

const compose = (...funcs) => x =>
    funcs.reduceRight((composed, f) => f(composed), x);

const createStore = (reducer, initialState, middlewares) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [state, dispatch] = useReducer(reducer, initialState);
    if (typeof middlewares !== 'undefined') {
        const middlewareAPI = {
            getState: () => state,
            dispatch: action => dispatch(action),
        };

        const chain = middlewares.map(middleware => middleware(middlewareAPI));
        const enhancedDispatch = compose(...chain)(dispatch);
        return { state, dispatch: enhancedDispatch };
    }

    return { state, dispatch };
};

export default createStore;
