import { defineMessages } from 'react-intl.macro';

const messages = defineMessages({
    upcomingAndActiveClassesBtnLabel: {
        id: 'studentLandingPage.upcomingAndActiveClassesBtnLabel',
        defaultMessage: 'Upcoming & Active',
    },
    pastClassesBtnLabel: {
        id: 'studentLandingPage.pastClassesBtnLabel',
        defaultMessage: 'Past Classes',
    },
    segmentedButtonLabel: {
        id: 'studentLandingPage.segmentedButtonLabel',
        defaultMessage: 'Filter',
    },
    noContentHeading: {
        id: 'studentLandingPage.noContentHeading',
        defaultMessage: 'No classes to display',
    },
    noContentbody: {
        id: 'studentLandingPage.noContentbody',
        defaultMessage:
            'Once you have completed an AWS Builder Lab class it will display here.',
    },
    noUpcomingBody: {
        id: 'studentLandingPage.noUpcomingBody',
        defaultMessage:
            'If you are registered for a class and do not see it listed, please check back {num} hours prior to the class as rosters can be updated the day before, or contact your training administrator. ',
    },
    searchClassesBtnLabel: {
        id: 'studentLandingPage.searchClassesBtnLabel',
        defaultMessage: 'Search classes',
    },
    loaderMessage: {
        id: 'studentLandingPage.loaderMessage',
        defaultMessage: 'loading...',
    },
});

export default messages;
