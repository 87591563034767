import moment from 'moment';

/**
 * @param {Number} start A Moment Start date/time
 * @param {Number} end A Moment End date/time
 * @param {String} locale Self-explanatory, you should get this value from React-Intl
 * @return {String} Duration string in either days, hours, or minutes
 *
 * @note There is an assumption that all classes will be longer than 1 minute in duration
 */
export const formatDuration = (start, end, locale) => {
    return ['minutes', 'hours', 'days']
        .map(u => {
            const diff = Math.ceil(end.diff(start, u, true));
            if (diff > 1) {
                const dur = moment.duration(diff, u);
                return dur.locale(locale).humanize();
            }
            return null;
        })
        .filter(v => v)
        .pop();
};

export const formatTimezone = timezone =>
    timezone.replace(/\//, ' / ').replace(/_/, ' ');

export const formatUTC = time => `(UTC${time.format('Z')})`;

export const formatTime = time => `${time.format('LL')} @ ${time.format('LT')}`;
