import React, { createContext, useContext, useState } from 'react';
import { useQuery } from 'react-query';
import { useApiClient } from '../utils/hooks/useApiClient';

const ActiveTrainingsContext = createContext();

export const ActiveTrainingsProvider = ({
    children,
    classroomId,
    enabled,
    initialState = [],
}) => {
    const [trainings, trainingsSet] = useState(initialState);
    return (
        <ActiveTrainingsContext.Provider
            value={{
                trainings,
                trainingsSet,
                enabled,
                classroomId,
            }}
        >
            {children}
        </ActiveTrainingsContext.Provider>
    );
};

export const useActiveTrainings = () => {
    const bffClient = useApiClient();
    const { trainings, trainingsSet, enabled, classroomId } = useContext(
        ActiveTrainingsContext
    );
    const { refetch } = useQuery(
        ['listActiveTrainings', classroomId],
        () => bffClient.listActiveTrainings(classroomId),
        {
            enabled: !!(enabled && bffClient.hasLoadedToken),
            onSuccess: trainingsSet,
        }
    );
    return { trainings, refetch: enabled ? refetch : () => {} };
};
