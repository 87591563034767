import { fetcherReducer, loggerMiddleware } from '../../reducers';
import createStore from '../store';
import { apis } from '..';

export const addApiStates = apiMap => {
    const mappedApis = {};
    for (const key in apiMap) {
        mappedApis[key] = {
            loading: true,
            error: null,
            data: null,
            ...apiMap[key],
        };
    }
    return mappedApis;
};

const INITIAL_STATES = addApiStates(apis);

const useQuery = name => {
    const { state, dispatch: fetchDispatch } = createStore(
        fetcherReducer,
        INITIAL_STATES[name],
        [loggerMiddleware]
    );

    return {
        state,
        fetchDispatch,
    };
};

export default useQuery;
