import { defineMessages } from 'react-intl.macro';

const messages = defineMessages({
    physicalLocationType: {
        id: 'classCard.physicalLocationType',
        defaultMessage: 'Live classroom',
    },
    virtualLocationType: {
        id: 'classCard.virtualLocationType',
        defaultMessage: 'Virtual live classroom',
    },
    openLabBtnLabel: {
        id: 'classCard.openLabBtnLabel',
        defaultMessage: 'Open',
    },
    activeStatusBadge: {
        id: 'classCard.activeStatusBadge',
        defaultMessage: 'Active',
    },
    completedStatusBadge: {
        id: 'classCard.completedStatusBadge',
        defaultMessage: 'Completed',
    },
    tooEarlyWarning: {
        id: 'classCard.tooEarlyWarning',
        defaultMessage: 'Classroom is available 1 hour prior to class start',
    },
});

export default messages;
