import React from 'react';
import {
    Box,
    Button,
    Container,
    SpaceBetween,
    TextContent,
} from '@amzn/awsui-components-react';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import messages from './LogoutPage.messages';
import './LogoutPage.scss';
import { Helmet } from 'react-helmet';

const {
    modalTitle,
    modalTrainingBtnLabel,
    modalSigninBtnLabel,
    modalBody,
    signoutInstructions,
    signoutInstructionsLink,
    awsPartnerSignoutInstructions,
    awsPartnerSignoutInstructionsLink,
    instructionsHeader,
    pageTitle,
} = messages;

const LogoutPage = ({ idp, classroomId }) => {
    const history = useHistory();
    const { formatMessage } = useIntl();
    const signInRedirectURL =
        classroomId && classroomId !== '/' ? `/class/${classroomId}` : `/`;
    return (
        <>
            <Helmet>
                <title>
                    {`${formatMessage(
                        pageTitle
                    )} - AWS Training and Certification`}
                </title>
            </Helmet>
            <div className="logout-wrapper">
                <Container
                    data-test="app-modal"
                    header={<Box variant="h1">{formatMessage(modalTitle)}</Box>}
                    footer={
                        <Box float="right">
                            <SpaceBetween direction="horizontal" size="m">
                                <Button
                                    iconAlign="left"
                                    iconName="external"
                                    href="https://aws.training/"
                                    data-testid="logout-page-navigate-to-aws-training"
                                >
                                    {formatMessage(modalTrainingBtnLabel)}
                                </Button>
                                <Button
                                    variant="primary"
                                    onClick={() =>
                                        history.push(signInRedirectURL)
                                    }
                                    data-testid="logout-page-sign-in-redirect"
                                >
                                    {formatMessage(modalSigninBtnLabel)}
                                </Button>
                            </SpaceBetween>
                        </Box>
                    }
                >
                    <LogoutMainContent idp={idp} />
                </Container>
            </div>
        </>
    );
};

const ExternalLink = ({ url, text }) => {
    const { formatMessage } = useIntl();
    return (
        <a target="_blank" rel="noreferrer noopener" href={url}>
            {formatMessage(text)}
        </a>
    );
};

const AmazonFederate = 'AmazonFederate';
const AWSBuilderID = 'AWSBuilderID';
const LoginWithAmazon = 'LoginWithAmazon';
const AWSPartnerNetwork = 'AWSPartnerNetwork';

const idpLogoutInfo = {
    default: {
        displayName: '',
        link: null,
        instructions: modalBody,
    },
    [AWSBuilderID]: {
        displayName: 'AWS Builder ID',
        link: (
            <ExternalLink
                text={signoutInstructionsLink}
                url="https://docs.aws.amazon.com/signin/latest/userguide/sign-out-all-aws_builder_id.html"
            />
        ),
        instructions: signoutInstructions,
    },
    [LoginWithAmazon]: {
        displayName: 'Amazon',
        link: (
            <ExternalLink
                text={signoutInstructionsLink}
                url="https://www.amazon.com/gp/help/customer/display.html?nodeId=GQL359FK7DM8294C"
            />
        ),
        instructions: signoutInstructions,
    },
    [AmazonFederate]: {
        displayName: 'Amazon Federate',
        link: (
            <ExternalLink
                text={signoutInstructionsLink}
                url="https://w.amazon.com/bin/view/NextGenMidway/FAQ#How_do_I_logout_of_NextGen_Midway_so_I_can_test_login_.2F_reset_my_timeout_to_20_hours_before_starting_work_I_don.27t_want_interrupted.3F"
            />
        ),
        instructions: signoutInstructions,
    },
    [AWSPartnerNetwork]: {
        displayName: 'AWS Partner Network',
        link: (
            <ExternalLink
                text={awsPartnerSignoutInstructionsLink}
                url="https://partnercentral.awspartner.com/home"
            />
        ),
        instructions: awsPartnerSignoutInstructions,
    },
};

const LogoutMainContent = ({ idp }) => {
    const { formatMessage } = useIntl();
    const messaging =
        idp in idpLogoutInfo ? idpLogoutInfo[idp] : idpLogoutInfo.default;
    return (
        <TextContent>
            {messaging.displayName && (
                <p>
                    {formatMessage(instructionsHeader, {
                        provider: <b>{messaging.displayName}</b>,
                    })}
                </p>
            )}

            <p>
                {formatMessage(messaging.instructions, {
                    link: messaging.link,
                })}
            </p>
        </TextContent>
    );
};

export default LogoutPage;
