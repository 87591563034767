import React, { useState } from 'react';
import { Button, StatusIndicator } from '@amzn/awsui-components-react';
import { useIntl } from 'react-intl';

import messages from './LabCard.messages';
import BaseCard from '../BaseCard/BaseCard';
import { useApiClient } from '../utils/hooks/useApiClient';
import { useActiveTrainings } from '../ActiveTrainingsProvider';
import {
    isActiveStatus,
    isCancelable,
    isReadyStatus,
    isRunningStatus,
} from '../utils/lab-statuses';
import './LabCard.scss';

const {
    disabledLabBtnLabel,
    disabledLabBtnText,
    enabledLabBtnLabel,
    enabledLabBtnText,
    endLabBtnLabel,
    preloadingLabStatus,
    readyLabStatus,
    runningLabStatus,
} = messages;

const LaunchButton = ({ disabled, onClick, index }) => {
    const { formatMessage } = useIntl();
    const messageToRender = disabled ? disabledLabBtnLabel : enabledLabBtnLabel;
    return (
        <Button
            disabled={disabled}
            onClick={onClick}
            ariaLabel={formatMessage(messageToRender, { index: index + 1 })}
        >
            {disabled
                ? formatMessage(disabledLabBtnText)
                : formatMessage(enabledLabBtnText)}
        </Button>
    );
};

const EndLabButton = ({ status, labId }) => {
    const [isLoading, isLoadingSet] = useState(false);
    const { formatMessage } = useIntl();
    const bffClient = useApiClient();
    const { refetch } = useActiveTrainings();
    if (!isCancelable(status)) return null;
    const performEndLabCall = async () => {
        isLoadingSet(true);
        try {
            await bffClient.cancelStudentTraining(labId);
            setTimeout(async () => {
                await refetch();
                isLoadingSet(false);
            }, 3000);
        } catch (err) {
            console.error(err);
            isLoadingSet(false);
        }
    };
    return (
        <span className="end-lab-btn-spacer">
            <Button onClick={performEndLabCall} loading={isLoading}>
                {formatMessage(endLabBtnLabel)}
            </Button>
        </span>
    );
};

const LabStatus = ({ status, bunsenLabStatus }) => {
    const { formatMessage } = useIntl();
    const testId = 'lab-card__status';
    if (isRunningStatus(bunsenLabStatus)) {
        return (
            <span data-testid={testId}>
                <StatusIndicator type="in-progress">
                    {formatMessage(runningLabStatus)}
                </StatusIndicator>
            </span>
        );
    } else if (isReadyStatus(status)) {
        return (
            <span data-testid={testId}>
                <StatusIndicator type="success">
                    {formatMessage(readyLabStatus)}
                </StatusIndicator>
            </span>
        );
    } else if (isActiveStatus(status)) {
        return (
            <span data-testid={testId}>
                <StatusIndicator type="info">
                    {formatMessage(preloadingLabStatus)}
                </StatusIndicator>
            </span>
        );
    }
    return null;
};

const LabCard = ({
    title,
    duration,
    description,
    disabled,
    onClick,
    index,
    labStatus = '',
    labId = '',
    bunsenLabStatus = '',
    globals = window,
}) => {
    return (
        <BaseCard
            title={title}
            duration={duration}
            description={description}
            align="right"
            badge={
                <LabStatus
                    status={labStatus}
                    bunsenLabStatus={bunsenLabStatus}
                />
            }
        >
            <EndLabButton status={labStatus} labId={labId} />
            <LaunchButton disabled={disabled} onClick={onClick} index={index} />
        </BaseCard>
    );
};

export default LabCard;
