export const LAB_STARTED = 'LAB_STARTED';
export const RESOURCES_READY = 'RESOURCES_READY';
export const INITIALIZING = 'INITIALIZING';
export const CONSOLE_AVAILABLE = 'CONSOLE_AVAILABLE';
export const READY = 'READY';
export const SESSION_ENDED = 'SESSION_ENDED';
export const PROVISIONING = 'PROVISIONING';
const CANCELABLE_STATES = [
    CONSOLE_AVAILABLE,
    RESOURCES_READY,
    PROVISIONING,
    READY,
];
const READY_STATES = [CONSOLE_AVAILABLE, READY];

export const isCancelable = status => CANCELABLE_STATES.includes(status);
export const isActiveStatus = status => status && status !== SESSION_ENDED;
export const isReadyStatus = status => READY_STATES.includes(status);
export const isRunningStatus = bunsenLabStatus => bunsenLabStatus === 'RUNNING';
