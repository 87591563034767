import React from 'react';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Box, Button, Container } from '@amzn/awsui-components-react';

import componentMessages from './ClassErrorContainer.messages';
import messages from '../ClassErrorModal/ClassErrorModal.messages';
import errorPageMessages from '../ErrorPage/ErrorPage.messages';
import './ClassErrorContainer.scss';
import {
    TOO_EARLY_ERROR_CAUSE,
    APP_ERROR_NAME,
    NOT_AUTHORIZED_TYPE,
    NOT_FOUND_ERROR_TYPE,
    AUTH_ERROR_TYPE,
} from '../utils';

const {
    tooEarlyErrorHeader,
    tooEarlyErrorBody,
    tooEarlyBackToHomeBtn,
    unauthorizedErrorBody,
} = componentMessages;

const errorMessages = {
    [AUTH_ERROR_TYPE]: {
        header: messages.authorizationErrorHeader,
        body: messages.authorizationErrorBody,
    },
    [NOT_AUTHORIZED_TYPE]: {
        header: messages.authorizationErrorHeader,
        body: unauthorizedErrorBody,
    },
    [APP_ERROR_NAME]: {
        header: messages.appErrorHeader,
        body: messages.appErrorBody,
    },
    [TOO_EARLY_ERROR_CAUSE]: {
        header: tooEarlyErrorHeader,
        body: tooEarlyErrorBody,
    },
    [NOT_FOUND_ERROR_TYPE]: {
        header: errorPageMessages.pageNotFound,
        body: errorPageMessages.pageAdvice,
    },
};

const isEarlyEnrollmentError = err =>
    err?.cause === TOO_EARLY_ERROR_CAUSE && err?.status === 409;

const parseError = error => {
    if (isEarlyEnrollmentError(error)) {
        return errorMessages[TOO_EARLY_ERROR_CAUSE];
    }
    return errorMessages[(error?.type)] || errorMessages[APP_ERROR_NAME];
};

const ClassErrorContainerFooter = ({ error }) => {
    const { formatMessage } = useIntl();
    const history = useHistory();
    const backToHome = e => {
        e.preventDefault();
        history.push('/');
    };
    return (
        <Box textAlign="right" data-testid="class-page__too-early-warning">
            <span className="class-error-container_btn-spacer">
                <Button variant="primary" onClick={backToHome}>
                    {formatMessage(tooEarlyBackToHomeBtn)}
                </Button>
            </span>
        </Box>
    );
};

const ClassErrorContainer = ({ error }) => {
    const { header, body } = parseError(error);
    const { formatMessage } = useIntl();
    return (
        <>
            <div className="class-error-container__empty-banner" />
            <section>
                <Container
                    header={
                        !!header && (
                            <Box variant="h2">{formatMessage(header)}</Box>
                        )
                    }
                    footer={
                        isEarlyEnrollmentError(error) && (
                            <ClassErrorContainerFooter error={error} />
                        )
                    }
                >
                    {!!body && <Box>{formatMessage(body)}</Box>}
                </Container>
            </section>
        </>
    );
};

export default ClassErrorContainer;
