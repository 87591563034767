import { defineMessages } from 'react-intl.macro';

const messages = defineMessages({
    sectionTitle: {
        id: 'jamTrainings.sectionTitle',
        defaultMessage: 'Jams',
    },
    subtitle: {
        id: 'jamTrainings.subtitle',
        defaultMessage: 'Jam event will be made available by instructors',
    },
});

export default messages;
