import { defineMessages } from 'react-intl.macro';

export const breadcrumbMessages = defineMessages({
    builderLabs: {
        id: 'classBanner.breadcrumbs.builderLabs',
        defaultMessage: 'AWS Builder Labs',
    },
    myClasses: {
        id: 'classBanner.breadcrumbs.myClasses',
        defaultMessage: 'My Classes',
    },
});

export const commonBreadcrumbs = formatMessage => [
    {
        text: formatMessage(breadcrumbMessages.myClasses),
        href: '/',
    },
];
