import { recordError } from './analytics';

export const JS_ERROR_TYPE = 'Error';
export const ERROR_PAGE_TYPE = 'ErrorPage';
export const AUTH_ERROR_TYPE = 'AuthError';
export const NOT_AUTHORIZED_TYPE = 'NotAuthorizedError';
export const NOT_FOUND_ERROR_TYPE = 'NotFoundError';
export const SERVICE_ERROR_TYPE = 'ServiceError';
export const INVALID_REQUEST_TYPE = 'InvalidRequest';

export const APP_ERROR_NAME = 'AppError';
export const FETCH_ERROR_NAME = 'FetchError';

export const TOO_EARLY_ERROR_CAUSE = 'EnrollmentRequestTooEarly';
export const MISSING_PII_CAUSE = 'MissingPIIError';
export const NOT_AUTHORIZED_CAUSE = 'Unauthorized';
export const UNVERIFIED_EMAIL_CAUSE = 'UnverifiedEmailError';

export class AppError extends Error {
    constructor(message, params = {}) {
        super(message.replace(/Error:\s/, ''));

        // Maintains proper stack trace for where our error was thrown (only available on V8)
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, AppError);
        }

        this.type = params.type || JS_ERROR_TYPE;
        this.name = params.name || APP_ERROR_NAME;
        this.status = params.status;
        this.cause = params.cause || APP_ERROR_NAME;
        this.date = new Date();

        recordError({
            message,
            name: this.name,
            type: this.type,
        });
    }
}
