import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { Box, Icon } from '@amzn/awsui-components-react';
import messages from './BaseCard.messages';
import classMetaMessages from '../ClassMeta/ClassMeta.messages';
import './BaseCard.scss';
import { elementHasOverflown } from '../utils';

const { viewAllToggleLink, viewLessToggleLink } = messages;

const BaseCard = ({
    title,
    duration,
    description,
    align = 'left',
    badge,
    children,
    subHeader,
    className,
    globals = window,
}) => {
    const { formatMessage } = useIntl();
    const containerClasses = `lab-card__actions ${align}`;
    const [windowSize, windowSizeSet] = useState({});
    const [expand, expandSet] = useState(false);
    const [showExpand, showExpandsSet] = useState(false);
    const descriptionRef = useRef();
    useEffect(() => {
        const handleResize = () => {
            windowSizeSet({
                width: globals.innerWidth,
                height: globals.innerHeight,
            });
        };
        globals.addEventListener('resize', handleResize);
        handleResize();
        return () => globals.removeEventListener('resize', handleResize);
    }, [globals]);

    const windowSizeDep = JSON.stringify(windowSize);
    useEffect(() => {
        showExpandsSet(elementHasOverflown(descriptionRef.current));
    }, [description, windowSizeDep]);
    return (
        <div className={`lab-card ${className || ''}`}>
            <div className="lab-card__header">
                <h3>{title}</h3>
                {duration ? (
                    <span className="lab-card__duration">
                        <span
                            role="img"
                            aria-label={formatMessage(
                                classMetaMessages.duration
                            )}
                        >
                            <Icon
                                className="lab-card__duration-icon"
                                name="status-pending"
                                variant="subtle"
                            />
                        </span>{' '}
                        {duration}
                    </span>
                ) : null}
                {badge ? <Box float="right">{badge}</Box> : null}
            </div>
            {subHeader && <div>{subHeader}</div>}

            <div className="lab-card__description-container">
                <p
                    ref={descriptionRef}
                    className={`lab-card__description ${
                        expand ? 'expanded' : ''
                    }`}
                >
                    {description}
                </p>
                <div className="expand-toggle">
                    <button
                        variant="link"
                        className={`faux-link ${showExpand ? '' : 'hide'}`}
                        onClick={() => expandSet(!expand)}
                    >
                        {!expand
                            ? formatMessage(viewAllToggleLink)
                            : formatMessage(viewLessToggleLink)}
                    </button>
                </div>
            </div>
            <div className={containerClasses}>{children}</div>
        </div>
    );
};

export default BaseCard;
