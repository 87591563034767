import React from 'react';
import { injectIntl } from 'react-intl';
import get from 'lodash.get';

import messages from './ClassMain.messages';
import Section, { SectionTitle } from '../Section';
import ClassMeta from '../ClassMeta';
import ClassLabListHeader from '../ClassLabListHeader';
import LabList from '../LabList';
import StartTrainingQuery from '../StartTrainingQuery';
import TrainingPermissionsQuery from '../TrainingPermissionsQuery';
import './ClassMain.scss';
import JamTrainings from '../JamTrainings/JamTrainings';

const ClassMain = ({ intl: { formatMessage }, classData }) => {
    const abstract = get(classData, 'course.abstract');
    const books = get(classData, 'course.books');
    const langLocale = get(classData, 'course.langLocale');
    const startsOn = get(classData, 'classroom.startsOn');
    const endsOn = get(classData, 'classroom.endsOn');
    const classId = get(classData, 'classroom.classroomId');
    const title = get(classData, 'course.title');
    const titleRegex = new RegExp(`^\\s*${title}\\s*[-:]\\s*`, 'i');
    const hasLabs =
        !!classData?.content && !!Object.keys(classData?.content).length;

    return (
        <StartTrainingQuery
            formatMessage={formatMessage}
            classroomId={classId}
            langLocale={langLocale}
            endsOn={endsOn}
            contentData={classData?.content}
            titleRegex={titleRegex}
            courseTitle={title}
        >
            <TrainingPermissionsQuery>
                {props => {
                    const {
                        queries: { callStartTraining },
                        data: { activeTrainings },
                    } = props;

                    return (
                        <>
                            <div className="class-meta-container">
                                {classData ? (
                                    <ClassMeta data={classData} />
                                ) : null}
                            </div>
                            <div
                                className={
                                    hasLabs ? 'class-lab-list-container' : ''
                                }
                            >
                                {abstract && (
                                    <Section
                                        ariaRole="main"
                                        data-test="main-section"
                                    >
                                        <SectionTitle data-test="description-heading">
                                            {formatMessage(
                                                messages.descriptionHeading
                                            )}
                                        </SectionTitle>
                                        <p data-test="abstract">{abstract}</p>
                                    </Section>
                                )}
                                {hasLabs && (
                                    <Section>
                                        {startsOn && hasLabs && (
                                            <ClassLabListHeader
                                                startsOn={startsOn}
                                                classId={classId}
                                                books={books}
                                                showLegacyLabButton
                                            />
                                        )}
                                        <div data-test="lab-list">
                                            <LabList
                                                data={classData}
                                                onClassClick={callStartTraining}
                                                activeTrainings={
                                                    activeTrainings
                                                }
                                                titleRegex={titleRegex}
                                            />
                                        </div>
                                    </Section>
                                )}
                            </div>
                            <JamTrainings
                                trainings={classData?.trainings || []}
                                activeTrainings={activeTrainings}
                                courseTitle={classData?.course?.title}
                            />
                        </>
                    );
                }}
            </TrainingPermissionsQuery>
        </StartTrainingQuery>
    );
};

export default injectIntl(ClassMain);
