import { defineMessages } from 'react-intl.macro';

const messages = defineMessages({
    disabledLabBtnText: {
        id: 'labCard.disabledLabBtnText',
        description: 'button text in lab card when lab is disabled',
        defaultMessage: 'Unavailable',
    },
    disabledLabBtnLabel: {
        id: 'labCard.disabledLabBtnLabel',
        description: 'disabled button label for accessibility',
        defaultMessage: 'Unavailable: Lab {index}',
    },
    enabledLabBtnText: {
        id: 'labCard.enabledLabBtnText',
        description: 'button text in lab card when lab is enabled',
        defaultMessage: 'Launch',
    },
    enabledLabBtnLabel: {
        id: 'labCard.enabledLabBtnLabel',
        description: 'enabled button label for accessibility',
        defaultMessage: 'Launch: Lab {index}',
    },
    endLabBtnLabel: {
        id: 'labCard.endLabBtnLabel',
        defaultMessage: 'End lab',
    },
    preloadingLabStatus: {
        id: 'labCard.preloadingLabStatus',
        defaultMessage: 'Preloading',
    },
    readyLabStatus: {
        id: 'labCard.readyLabStatus',
        defaultMessage: 'Preloaded',
    },
    runningLabStatus: {
        id: 'labCard.runningLabStatus',
        defaultMessage: 'Running',
    },
});

export default messages;
