import { isDevelopment } from '.';

const printLogs = (type, logs) => {
    if (Array.isArray(logs)) {
        logs.forEach(log => {
            if (Array.isArray(log)) {
                console[type](...log);
            } else {
                console[type](log);
            }
        });
    } else {
        console[type](logs);
    }
};

const setupLog = (type, opts = {}) => {
    if (!isDevelopment()) return;

    const { group, logs } = opts;

    if (group) console.groupCollapsed(group);
    printLogs(type, logs || opts);
    if (group) console.groupEnd();
};

const log = opts => setupLog('log', opts);
const info = opts => setupLog('info', opts);
const warning = opts => setupLog('warning', opts);
const debug = opts => setupLog('debug', opts);
const error = opts => setupLog('error', opts);

export default { log, info, warning, debug, error };
