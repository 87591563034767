import React, { useEffect } from 'react';
import Loader from '../Loader';
import ClassErrorModal from '../ClassErrorModal/ClassErrorModal';
import messages from './withUserAttributes.messages';

import { useQuery } from '../utils';
import { useUser } from '../utils/user';
import { performFetch } from '../sagas';

/**
 * Component decorator which obtains a user's attributes and passes them down to the child component
 */
const withUserAttributes = Component => () => {
    const { idToken } = useUser();
    const {
        fetchDispatch: getUserAttributesDispatch,
        state: getUserAttributesState,
    } = useQuery('getUserAttributes');
    const {
        data: getUserAttributesData,
        error: getUserAttributesError,
        loading: getUserAttributesLoading,
    } = getUserAttributesState;

    useEffect(() => {
        if (idToken) {
            performFetch(getUserAttributesDispatch, {
                api: getUserAttributesState,
                idToken,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idToken]);

    return (
        <Loader isLoading={getUserAttributesLoading}>
            {!!getUserAttributesData && (
                <Component attributes={getUserAttributesData} />
            )}
            <ClassErrorModal
                error={getUserAttributesError}
                customMessages={messages}
            />
        </Loader>
    );
};

export default withUserAttributes;
