import { Alert } from '@amzn/awsui-components-react';
import React, { useEffect, useState } from 'react';

const AlertBanner = ({ globals = window }) => {
    const [banner, setBanner] = useState({
        bannerVisible: false,
        bannerMessage: '',
        bannerType: 'info',
    });

    const dismissAlertBanner = () => {
        setBanner({
            bannerVisible: false,
            bannerMessage: '',
            bannerType: 'info',
        });
    };

    useEffect(() => {
        const cleanup = () => {
            globals.sessionStorage.removeItem('bannerVisible');
            globals.sessionStorage.removeItem('bannerMessage');
            globals.sessionStorage.removeItem('bannerType');
        };

        const bannerVisible =
            globals.sessionStorage.getItem('bannerVisible') === 'true';
        const bannerMessage = globals.sessionStorage.getItem('bannerMessage');
        const bannerType = globals.sessionStorage.getItem('bannerType');

        if (bannerVisible && bannerMessage && bannerType) {
            setBanner({ bannerVisible, bannerMessage, bannerType });
        }

        cleanup();
    }, [globals.sessionStorage, globals.sessionStorage.length]);

    return (
        <div>
            <Alert
                onDismiss={dismissAlertBanner}
                visible={banner.bannerVisible}
                dismissAriaLabel="Close alert"
                dismissible
                type={banner.bannerType}
            >
                {banner.bannerMessage}
            </Alert>
        </div>
    );
};

export default AlertBanner;
