import uuid from 'uuid4';
import { poll } from './';
import environment from '../.config/environment';
import logger from './logger';

const {
    IS_SERVER_LOGGING_DISABLED,
    authorizer: { clientId, issuer } = {},
} = environment(window);

const UUID_KEY = 'aws.beaker.clientLoggingUUID';

export const ANALYTICS_PROVIDER_NAME = 'StudentUIAnalyticsProvider';

export const sanitizeName = (obj = {}) => {
    let name = obj.name || (obj.constructor && obj.constructor.name);
    // Format name
    if (name && name.includes('Error')) {
        return `_error.${name.replace('Error', '').toLowerCase()}`;
    }
    return name;
};

const getUserKey = () => {
    let oidcInfo = null;
    try {
        const key = `oidc.user:${issuer}:${clientId}`;
        const raw = sessionStorage.getItem(key);
        oidcInfo = JSON.parse(raw);
    } catch (err) {
        return undefined;
    }
    const { profile: { hat_id } = {} } = oidcInfo || {};
    return hat_id;
};

export class StudentUIAnalyticsProvider {
    constructor(category, providerName) {
        this.category = category || 'Analytics';
        this.providerName = providerName;
    }

    anonymousId() {
        this.clientLoggingUUID =
            this.clientLoggingUUID ||
            sessionStorage.getItem(UUID_KEY) ||
            uuid();
        sessionStorage.setItem(UUID_KEY, this.clientLoggingUUID);
        return this.clientLoggingUUID;
    }

    configure(config) {
        this.config = config;
        return config;
    }

    // record events and returns true if succeeds
    record({ event = {} }) {
        const data = {
            ...event,
            name: sanitizeName(event.error || event),
            userId: getUserKey(),
            sessionId: this.anonymousId(),
        };

        if (IS_SERVER_LOGGING_DISABLED) {
            logger.log(
                `StudentUIAnalyticsProvider server logging disabled. data: ${JSON.stringify(
                    data,
                    null,
                    2
                )}`
            );
            return;
        }

        const { clientLoggingUrl } = this.config;

        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        };

        let trackingError;

        const postData = async () => {
            const res = await fetch(clientLoggingUrl, options);
            if (!res.ok)
                throw new Error(
                    `Failed to fetch logging endpoint, statuscode: ${
                        res.status
                    }`
                );
            return res.ok;
        };

        const { start } = poll(postData, {
            timeout: 5000,
            onError: err => {
                trackingError = trackingError || err;
                if (err === 'Retry limit reached') throw trackingError;
            },
        });

        return start();
    }

    getCategory() {
        return this.category;
    }

    getProviderName() {
        return this.providerName;
    }
}
