import { defineMessages } from 'react-intl.macro';

const messages = defineMessages({
    tooEarlyErrorHeader: {
        id: 'classErrorContainer.tooEarlyErrorHeader',
        defaultMessage: "You can't access this classroom yet",
    },
    tooEarlyErrorBody: {
        id: 'classErrorContainer.tooEarlyErrorBody',
        defaultMessage: 'Classroom will open 1 hour before the class starts',
    },
    tooEarlyBackToHomeBtn: {
        id: 'classErrorContainer.tooEarlyBackToHomeBtn',
        defaultMessage: 'View my upcoming classes',
    },
    unauthorizedErrorBody: {
        id: 'classErrorContainer.unauthorizedErrorBody',
        defaultMessage:
            "Make sure you're signed in using the same email you used to enroll in the class and ask your instructor for assistance",
    },
});

export default messages;
